import Vue from 'vue'

import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
import router from './router'
import baseUrl from './utils/baseURL'
import mixins from './utils/minxA'
Vue.mixin(mixins)
Vue.prototype.$baseUrl = baseUrl
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// 引入boostrap

Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
