import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
let lang = ''
lang = localStorage.getItem('lang') || '/us-en'
const routes = [

  {
    path: '/',
    redirect: lang + '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/HomeView.vue')
  },
  {
    path: lang + '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/HomeView.vue')
  },

  {
    path: lang + '/newsroom',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/NewsPage.vue')
  },


  {
    path: lang + '/blog',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/blogPage.vue')
  },


  {
    path: lang + '/newsroom/detail-page-of-the-news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/newsDetail.vue')
  },

  {
    path: lang + '/blog/detail-page-of-the-blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/blogDetail.vue')
  },



  {
    path: lang + '/solutions',
    name: 'solutions',
    component: () => import(/* webpackChunkName: "about" */ '../views/solutions/soluTions.vue')

  },

  {
    path: lang + '/solutions/warehouse-and-distribution/packing-and-staging',
    name: 'solutions',
    component: () => import(/* webpackChunkName: "about" */ '../views/solutions/packingStaging.vue')

  },
  {
    path: lang + '/solutions/warehouse-and-distribution',
    name: 'solutions',
    component: () => import(/* webpackChunkName: "about" */ '../views/solutions/wareHouse.vue')

  },
  {
    path: lang + '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "about" */ '../views/proDucts.vue')
  },

  {
    path: lang + '/products/mobile-computers',
    name: 'mobile-computers',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/type/mobile-computers.vue')
  },
  //     Products/Mobile Computers/detail
  {
    path: lang + '/products/mobile-computers/handheld',
    name: 'handheld',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/handheld/handhelD.vue')
  },

  {
    path: lang + '/products/mobile-computers/wearable',
    name: 'wearable',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/handheld/wearAble.vue')
  },
  {
    path: lang + '/products/barcode-scanners',
    name: 'barcode-scanners',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/type/barcodeScanners.vue')
  },

  {
    path: lang + '/products/barcode-scanners/handheld-scanners',
    name: 'handheld',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/handheld/handhelDScanners.vue')
  },

  {
    path: lang + '/products/printers',
    name: 'barcode-scanners',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/type/printS.vue')
  },

  {
    path: lang + '/products/printers/printers',
    name: 'handheld',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/handheld/handhelDPrint.vue')
  },


  {
    path: lang + '/products/Tablets',
    name: 'barcode-scanners',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/type/tableS.vue')
  },


  {
    path: lang + '/products/Tablets/handheld-Tablets',
    name: 'handheld',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/handheld/handhelDTable.vue')
  },




  //     Products/barcode-scanners

  // about-meferi
  {
    path: lang + '/about-meferi',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutMeferi.vue')
  },

  // me40k
  {
    path: lang + '/products/mobile-computers/handheld/ME40K',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe40k.vue')
  },
  // me61k
  {
    path: lang + '/products/mobile-computers/handheld/ME61',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe61.vue')
  },


  // Me10
  {
    path: lang + '/products/mobile-computers/wearable/ME10',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe10.vue')
  },

  // me300r
  {
    path: lang + '/products/mobile-computers/wearable/MS300R',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMs300R.vue')
  },

  // ME30K
  {
    path: lang + '/products/mobile-computers/handheld/ME30K',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe30k.vue')
  },


  // MS35
  {
    path: lang + '/products/mobile-computers/handheld/MS35',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMs35.vue')
  },


  // ME65
  {
    path: lang + '/products/mobile-computers/handheld/ME65',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe65.vue')
  },

  // ME74
  {
    path: lang + '/products/mobile-computers/handheld/ME74',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMe74.vue')
  },

  // 手持
  // ms22c
  {
    path: lang + '/products/barcode-scanners/handheld-scanners/MS22C',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMs22c.vue')
  },




  // 打印机
  // ms22c
  {
    path: lang + '/products/printers/printers/MP4000D',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodMp4000d.vue')
  },

  // 平板
  // me300r
  {
    path: lang + '/products/Tablets/handheld-Tablets/Tablets',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/goods/goodTable.vue')
  },




  // meferiSortwareSuite
  {
    path: lang + '/products/meferi-software-suite',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/meferiSortwareSuite.vue')
  },

  {
    path: lang + '/products/software/me-mobilitydesk',
    name: 'memobilityDesk',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/memobilityDesk.vue')
  },
  {
    path: lang + '/products/software/ciao',
    name: 'memobilityDesk',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/ciaoDesk.vue')
  },

  {
    path: lang + '/products/software/mdm-shadowalk',
    name: 'memobilityDesk',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/mdmDesk.vue')
  },


  // meferiSortwareSuite/OmniIndustry Apps Suite
  {
    path: lang + '/products/meferi-software-suite/omniIndustry-apps-suite',
    name: 'productMobileComputersDetails1',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/mssOmniIndustryAppsSuite.vue')
  },


  {
    path: lang + '/products/meferi-software-suite/omniIndustry-suite',
    name: 'productMobileComputersDetails2',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/mssOmniIndustrySuite.vue')
  },


  // meferiSortwareSuite-detail
  {
    path: lang + '/products/meferi-software-suite/omniIndustry-apps-suite/sortwareDetail-delivery-assistant',
    name: 'sortwareDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/sortwareDetailDeliAss.vue')
  },

  {
    path: lang + '/products/meferi-software-suite/omniIndustry-apps-suite/sortwareDetail-wms-assistant',
    name: 'sortwareDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/sortwareDetailWmsAss.vue')
  },

  {
    path: lang + '/products/meferi-software-suite/omniIndustry-apps-suite/sortwareDetail-retailpro',
    name: 'sortwareDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/sortwareDetailRetailPro.vue')
  },

  {
    path: lang + '/products/meferi-software-suite/omniIndustry-apps-suite/sortwareDetail',
    name: 'sortwareDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sorfware/sortwareDetail.vue')
  },


  // resourceHub
  {
    path: lang + '/resource-hub',
    name: 'resourceHub',
    component: () => import(/* webpackChunkName: "about" */ '../views/faq/resourceHub.vue')
  },
  {
    path: lang + '/resource-hub/success-stories',
    name: 'resourceHub',
    component: () => import(/* webpackChunkName: "about" */ '../views/story/successStory.vue')
  },


  {
    path: lang + '/resource-hub/success-stories/eliminating-errors-and-boosting-speed-of-picking-with-hands-free-solution-wearable-me10-mobile-computer-and-ms300-ring-scanner',
    name: 'resourceHub',
    component: () => import(/* webpackChunkName: "about" */ '../views/story/storyMobile.vue')
  },
  // faq
  {
    path: lang + '/faq',
    name: 'faQuestion',
    component: () => import(/* webpackChunkName: "about" */ '../views/faq/faQuestion.vue')
  },
  //   Mobile Computing
  {
    path: lang + '/faq/mobile-computers',
    name: 'mobileComputers',
    component: () => import(/* webpackChunkName: "about" */ '../views/faq/faqMobileComputers.vue')
  },
  {
    path: lang + '/faq/mobile-computers/mobile-computer-what-is-it',
    name: 'mobileComputersDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/faq/faqMobileComputersDetail.vue')
  },

  // contact meffrie
  {
    path: lang + '/contact-meferi',
    name: 'contactMeferi',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/conTact.vue')
  },
  {
    path: lang + '/contact-meferi/contact-sales',
    name: 'contactMeferi',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/conTactSales.vue')
  },
  {
    path: lang + '/contact-meferi/support-center',
    name: 'contactMeferi',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/conTactSupport.vue')
  },



  {
    path: lang + '/index/security',
    name: 'contactMeferi',
    component: () => import(/* webpackChunkName: "about" */ '../views/indexRz.vue')
  },



  // sttaus
  {
    path: lang + '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/status/noPage.vue')
  },
  {
    path: lang + '/PageThatIsInDevelopment',
    name: 'builDing',
    component: () => import(/* webpackChunkName: "about" */ '../views/status/builDing.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 默认切换页面在头部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  let isHas = false
  routes.forEach(g => {
    if (g.path == to.path) {
      isHas = true
    }
  })
  // next({
  //   path: lang + "/404",
  // })
  if (isHas) {
    next()
  } else {
    console.log(to, 'to')
    next({
      path: lang + '/404',
      query: {
        url: to.path
      }
    })
  }
})

export default router



